<template>
  <Content class="container">
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="0" style="max-width: 1800px" size="small">
            <a-step :title="$t('logistics.consignment_create_order')" />
            <a-step :title="$t('logistics.add_goods')" />
            <a-step :title="$t('logistics.select_logistics')" />
            <a-step :title="$t('common.completed')" />
          </a-steps>
        </a-col>
      </a-row>
      <div class="mt-2 mb-3 ml-2">
        <strong class="mt-2 mb-3">{{ $t("logistics.order_info") }}</strong>
      </div>

      <a-form ref="refForm" :rules="rules" :model="data">
        <a-row>
          <a-col
            :span="24"
            :md="{ span: 24 }"
            :xl="{ span: 8 }"
            :xxl="{ span: 6 }"
          >
            <a-form-item
              :label="$t('logistics.relation_order_no')"
              :label-col="{
                sm: { span: 4 },
                md: { span: 3 },
                xl: { span: 6 },
                xxl: { span: 6 },
              }"
              :wrapper-col="{
                sm: { span: 20 },
                md: { span: 21 },
                xl: { span: 18 },
                xxl: { span: 18 },
              }"
              name="relationOrderNo"
            >
              <a-input v-model:value="data.relationOrderNo" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col
            :span="24"
            :md="{ span: 24 }"
            :xl="{ span: 8 }"
            :xxl="{ span: 6 }"
          >
            <a-form-item
              :label="$t('logistics.source_of_sales')"
              :label-col="{
                sm: { span: 4 },
                md: { span: 3 },
                xl: { span: 6 },
                xxl: { span: 6 },
              }"
              name="selectedSourceSales"
            >
              <a-select
                v-model:value="data.selectedSourceSales"
                :placeholder="$t('logistics.source_of_sales')"
                :options="
                  sourceSales.map((item, index) => ({
                    value: item.name,
                    label: item.name,
                    key: index,
                  }))
                "
              >
                <template #dropdownRender="{ menuNode: menu }">
                  <v-nodes :vnodes="menu" />
                  <a-divider style="margin: 4px 0" />
                  <div
                    style="padding: 4px 8px; cursor: pointer"
                    @mousedown="(e) => e.preventDefault()"
                    @click="handleShowModalAddSourceSales(menu)"
                  >
                    <plus-outlined />
                    {{ $t("logistics.new") }}
                  </div>
                </template>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col
            :span="24"
            :md="{ span: 24 }"
            :xl="{ span: 8 }"
            :xxl="{ span: 6 }"
          >
            <a-form-item
              :label="$t('warehouse.logistics_way')"
              :label-col="{
                sm: { span: 4 },
                md: { span: 3 },
                xl: { span: 6 },
                xxl: { span: 6 },
              }"
              name="selectedAgentOrderLogisticsType"
            >
              <a-select
                v-model:value="data.selectedAgentOrderLogisticsType"
                :placeholder="$t('common.please_select')"
              >
                <a-select-option
                  v-for="item in agentOrderLogisticsTypeEnum"
                  :key="item"
                  :value="item"
                >
                  {{ $t($enumLangkey("agentOrderLogisticsType", item)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <div
          v-if="
            data.selectedAgentOrderLogisticsType ==
            agentOrderLogisticsTypeEnum.seLogistics
          "
        >
          <a-row>
            <a-col
              :span="24"
              :md="{ span: 24 }"
              :xl="{ span: 8 }"
              :xxl="{ span: 6 }"
            >
              <a-form-item
                name="recipients"
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                }"
                :label="$t('logistics.recipients')"
              >
                <a-input v-model:value="data.recipients" v-user-name-filter />
              </a-form-item>
            </a-col>
            <a-col
              :span="24"
              :sm="{ span: 24 }"
              :xl="{ span: 8 }"
              :xxl="{ span: 6 }"
            >
              <a-form-item
                :label="$t('logistics.contact_number')"
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                }"
                name="contactPhone"
              >
                <a-input-group style="display: flex" compact>
                  <a-select
                    style="width: 100px"
                    v-model:value="data.phoneCountryId"
                    :placeholder="$t('logistics.area_code')"
                    :loading="phoneCountryLoading"
                    option-label-prop="label"
                    optionFilterProp="search-key"
                    show-search
                    @change="handleCountryCodeChange"
                  >
                    <a-select-option
                      v-for="(item, index) in phoneCountryList"
                      :key="index"
                      :value="item.id"
                      :label="`+${item.telCode}`"
                      :search-key="
                        item.ioS2 +
                        item.cnName +
                        item.enName +
                        '+' +
                        item.telCode
                      "
                      :title="`+${item.telCode}(${getLanguageName(item)})`"
                      >+{{ item.telCode }}({{
                        getLanguageName(item)
                      }})</a-select-option
                    >
                  </a-select>
                  <a-input
                    style="flex: 1"
                    v-model:value="data.contactPhone"
                    :placeholder="$t('warehouse.phone')"
                    @change="handleCountryCodeChange"
                  />
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>

          <Address
            ref="refAddress"
            :addressData="data.addressData"
            :isShowButtonSaveCommon="false"
            :isShowSelectCommonAddress="false"
            :isShipAddress="false"
          ></Address>
        </div>

        <a-row>
          <a-col
            :span="24"
            :md="{ span: 24 }"
            :xl="{ span: 8 }"
            :xxl="{ span: 6 }"
          >
            <a-form-item
              :label="$t('logistics.consignment_warehouse')"
              :label-col="{
                sm: { span: 4 },
                md: { span: 3 },
                xl: { span: 6 },
                xxl: { span: 6 },
              }"
              :wrapper-col="{
                sm: { span: 20 },
                md: { span: 21 },
                xl: { span: 18 },
                xxl: { span: 18 },
              }"
              name="selectedConsignmentWarehouse"
            >
              <a-select
                v-model:value="data.selectedConsignmentWarehouse"
                @change="handleChangeSelectConsignmentWarehouse"
                optionFilterProp="search-key"
                show-search
                :placeholder="
                  $t('logistics.please_select_consignment_warehouse')
                "
              >
                <a-select-option
                  v-for="item in userOfInventoryWarehouses"
                  :title="item.warehouseNo + '(' + item.warehouseName + ')'"
                  :search-key="item.warehouseNo + item.warehouseName"
                  :key="item.warehouseId"
                  :value="item.warehouseId"
                >
                  {{ item.warehouseNo }} ({{ item.warehouseName }})
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <!-- button创建计划 -->
      <div class="mr-5 mt-5">
        <a-row type="flex" justify="end">
          <a-col :offset="0">
            <a-button
              type="primary"
              :loading="buttonNextStepLoading"
              @click="handleNextStep"
              >{{ $t("common.next_step") }}</a-button
            >
          </a-col>
        </a-row>
      </div>

      <!-- 新建销售来源 -->
      <a-modal
        v-model:visible="isShowModalAddSourceSales"
        :title="$t('logistics.new_source_of_sales')"
        @ok="handleSaveModalAddSourceSales"
        :cancelText="$t('common.cancel')"
        :okText="$t('common.save')"
        :centered="true"
        :confirm-loading="modalAddSourceSalesLoading"
      >
        <div>
          <input type="text" class="display-none" />
          <a-row type="flex" justify="center" align="middle">
            <a-col>
              <p class="height-100">
                {{ $t("logistics.please_input_source_of_sales") }}
              </p>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="15">
              <a-form-item>
                <a-input
                  v-model:value="data.inputNewSourceSale"
                  autoComplete="off"
                  required="true"
                  ref="refInputNewSourceSale"
                  @focus="
                    ($event) => {
                      $event.currentTarget.select();
                    }
                  "
                  @pressEnter="handleSaveModalAddSourceSales"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick, h } from "vue";
import {
  Row,
  Col,
  Steps,
  Divider,
  Input,
  Select,
  Button,
  Form,
  Modal,
  message,
} from "ant-design-vue";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import Content from "../../components/Content.vue";
import Address from "../../components/Address.vue";
import { useI18n } from "vue-i18n/index";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import { consignmentKeys } from "../../../consts/index";
import { useTab } from "../../../hooks/tabs/index";
import { elemFocus } from "../../../utils/domoperate";
import {
  setObjectSessionStorage,
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import {
  verifyMobilePhoneNumber,
  replaceBrackets,
  getName,
} from "../../../utils/general";
import {
  getGlobalCountrys,
  checkZipCodeValid,
} from "@/api/modules/common/index";
import {
  addSourceSales,
  getUserOfInventoryWarehouses,
  getSourceSales,
} from "../../../api/modules/consignment/index";
import { agentOrderLogisticsType as agentOrderLogisticsTypeEnum } from "../../../enum/enum.json";

export default {
  name: "consignment_order_create",
  components: {
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
    PlusOutlined,
    ADivider: Divider,
    Address,
    Content,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute, visitedRoutes } = useTab();
    const refForm = ref();
    const refAddress = ref();

    const refInputNewSourceSale = ref();

    const state = reactive({
      sourceSales: [],
      userOfInventoryWarehouses: [],
      isShowModalAddSourceSales: false,
      modalAddSourceSalesLoading: false,
      buttonNextStepLoading: false,
      phoneCountryLoading: false,
      phoneCountryList: [],
    });

    const data = reactive({
      inputNewSourceSale: "",
      relationOrderNo: "", //关联订单号
      selectedSourceSales: null,
      selectedAgentOrderLogisticsType: agentOrderLogisticsTypeEnum.seLogistics,
      recipients: "", //收件人
      contactPhone: "", //联系电话
      phoneCountryId: null, //联系电话
      selectedConsignmentWarehouse: null, //发货仓库Id
      consignmentWarehouseNo: "", //发货仓库编号
      selectedConsignmentWarehouseInfo: null, // 发货仓库

      addressData: {
        inputAddressAlias: "",
        selectedCommonAddress: "",
        commonAddressName: "", //发自
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: "",
        postCode: "",
      },
    });

    const getCountriesList = () => {
      state.phoneCountryLoading = true;
      getGlobalCountrys()
        .then(({ result }) => {
          state.phoneCountryList = result;
        })
        .catch(() => {})
        .finally(() => {
          state.phoneCountryLoading = false;
        });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const checkPhoneNumber = () => {
      data.contactPhone = replaceBrackets(data.contactPhone);
    };

    const handleCountryCodeChange = () => {
      checkPhoneNumber();
      refForm.value.validateFields(["contactPhone"]);
    };

    const handleCheckZipCodeValid = (result) => {
      return checkZipCodeValid({
        countryId: result.addressData.countryId,
        abbrCode: result.addressData.abbrCode,
        zipCode: result.addressData.postCode,
      });
    };

    const handleNextStep = () => {
      Promise.all([
        refForm.value.validate(),
        refAddress?.value?.refForm?.validate(),
      ])
        .then(() => {
          if (
            data.selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.thirdparty
            || data.selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.destruction
          ) {
            data.addressData = null;
            data.recipients = "";
            data.contactPhone = "";
            data.phoneCountryId = null;
          } else {
            let addressInfo = refAddress?.value?.getAddressInfo();
            data.addressData = data.addressData || {};
            Object.assign(data.addressData, addressInfo);
            data.addressData.receiptUserName = data.recipients; //方便算物流费的时候用
            data.addressData.contactPhone = data.contactPhone;
            data.addressData.phoneCountryId = data.phoneCountryId;
          }

          return data;
        })
        .then((result) => {
          if (
            data.selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.thirdparty
            || data.selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.destruction
          ) {
            return {
              result: true,
            };
          } else {
            if (!result.addressData.abbrCode) {
              //省份没有,就不验证邮编.(目前数据库中加拿大没省份数据)
              return {
                result: true,
              };
            }
            //验证邮编
            return handleCheckZipCodeValid(result);
          }
        })
        .then(({ result }) => {
          if (result) {
            if (data.phoneCountryId) {
                localStorage.setItem(
                consignmentKeys.createConsignmentOrderAreaCode,
                data.phoneCountryId
              );
            }
            //邮编验证正确或物流方式为第三方面单
            setObjectSessionStorage(
              consignmentKeys.createConsignmentOrderData,
              data
            );
            delVisitedRoute(router.currentRoute.value);
            router.push({ name: "consignment_order_add_product" });
          } else if (result === false) {
            // 邮编验证错误
            Modal.error({
              title: vueI18n.t("common.hint"),
              content: vueI18n.t(
                "warehouse.the_postal_code_you_entered_does_not_match"
              ),
              okText: vueI18n.t("common.return_to_modification"),
            });
          } else {
            // 邮编验证未知
            Modal.confirm({
              title: vueI18n.t("common.hint"),
              icon: h(ExclamationCircleOutlined),
              content: vueI18n.t(
                "warehouse.the_correctness_of_the_postal_code_you_entered_is_unknown"
              ),
              cancelText: vueI18n.t("common.return_to_modification"),
              okText: vueI18n.t("common.continue"),
              onOk: () => {
                if (data.phoneCountryId) {
                    localStorage.setItem(
                    consignmentKeys.createConsignmentOrderAreaCode,
                    data.phoneCountryId
                  );
                }
                setObjectSessionStorage(
                  consignmentKeys.createConsignmentOrderData,
                  data
                );
                delVisitedRoute(router.currentRoute.value);
                router.push({ name: "consignment_order_add_product" });
              },
            });
          }
        })
        .catch((res) => {})
        .finally(() => {
          state.buttonNextStepLoading = false;
        });
    };

    const handleShowConfim = () => {
      Modal.confirm({
        title: vueI18n.t("logistics.cannot_create_order"),
        icon: h(ExclamationCircleOutlined),
        content: vueI18n.t("logistics.consignment_warehouse_no_gooods"),
        centered: true,
        mask: true,
        maskClosable: false,
        keyboard: false,
        okText: vueI18n.t("common.confirm"),
        //cancelText: "取消",
        cancelButtonProps: { style: { display: "none" } },
        onOk() {
          delVisitedRoute(router.currentRoute.value);
          router.push({ name: "consignment_order_list" });
        },
      });
    };

    const funcSourceSales = async () => {
      getSourceSales({}).then((res) => {
        state.sourceSales = res.result;
      });
    };

    const funcGetUserOfInventoryWarehouses = async () => {
      getUserOfInventoryWarehouses().then((res) => {
        if (!res.result || res.result.length == 0) {
          handleShowConfim();
        } else {
          state.userOfInventoryWarehouses = res.result;
        }
      });
    };

    const rules = {
      relationOrderNo: [
        {
          required: true,
          message: () =>
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.relation_order_no"),
            ]),
        },
        {
          trigger: ["change", "blur"],
          pattern: /^[A-Za-z0-9\-]{5,35}$/,
          message: () =>
            vueI18n.t("warehouse.please_enter_the_correct_p0", [
              vueI18n.t("logistics.relation_order_no"),
            ]),
        },
      ],
      selectedSourceSales: [
        {
          required: true,
          whitespace: true,
          // trigger: ['change', 'blur'],
          message: () =>
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.source_of_sales"),
            ]),
        },
      ],
      selectedAgentOrderLogisticsType: [
        {
          type: "number",
          required: true,
          whitespace: true,
          message: () =>
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.logistics_way"),
            ]),
        },
      ],
      recipients: [
        {
          required: true,
          whitespace: true,
          message: () =>
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.recipients"),
            ]),
        },
        {
          trigger: ["change", "blur"],
          pattern: /^.{1,32}$/,
          message: vueI18n.t("common.p0_up_to_p1_characters", [
            vueI18n.t("logistics.recipients"),
            32,
          ]),
        },
      ],
      contactPhone: [
        {
          required: true,
          whitespace: true,
          validator: (rule, value) => {
            if (!data.phoneCountryId) {
              return Promise.reject(
                vueI18n.t("common.p0_is_required", [
                  vueI18n.t("logistics.area_code"),
                ])
              );
            }
            if (!value) {
              return Promise.reject(
                vueI18n.t("common.p0_is_required", [
                  vueI18n.t("logistics.contact_number"),
                ])
              );
            }
            let selectCountry = state.phoneCountryList.find(
              (item) => item.id === data.phoneCountryId
            );
            let countryIos2Code = selectCountry?.ioS2 ?? null;
            if (
              verifyMobilePhoneNumber(
                countryIos2Code,
                (selectCountry?.telCode ?? "") + value
              )
            ) {
              return Promise.resolve();
            } else {
              return Promise.reject(
                vueI18n.t("common.p0_incorrect_format", [
                  vueI18n.t("warehouse.phone"),
                ])
              );
            }
          },
        },
      ],
      selectedConsignmentWarehouse: [
        {
          required: true,
          whitespace: true,
          //trigger: ['change', 'blur'],
          message: () =>
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.consignment_warehouse"),
            ]),
        },
      ],
    };

    const handleShowModalAddSourceSales = (a) => {
      state.isShowModalAddSourceSales = true;
      funcFocusInput();
    };

    const handleSaveModalAddSourceSales = () => {
      if (!data.inputNewSourceSale) {
        message.error(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("logistics.source_of_sales"),
          ])
        );
        return false;
      }

      state.modalAddSourceSalesLoading = true;
      addSourceSales({
        name: data.inputNewSourceSale,
      })
        .then((res) => {
          state.sourceSales.push({
            id: res.result,
            name: data.inputNewSourceSale,
          });
          message.success(vueI18n.t("common.add_success"));
          data.selectedSourceSales = data.inputNewSourceSale;
          state.isShowModalAddSourceSales = false;
          data.inputNewSourceSale = "";
        })
        .finally(() => {
          state.modalAddSourceSalesLoading = false;
        });
    };

    const funcFocusInput = () => {
      nextTick(() => {
        elemFocus(refInputNewSourceSale.value.input, false);
      });
    };

    const handleChangeSelectConsignmentWarehouse = () => {
      data.selectedConsignmentWarehouseInfo =
        state.userOfInventoryWarehouses?.find(
          (x) => x.warehouseId == data.selectedConsignmentWarehouse
        );
      data.consignmentWarehouseNo =
        data.selectedConsignmentWarehouseInfo?.warehouseNo;
    };

    const getCacheData = () => {
      let phoneCountryId = localStorage.getItem(
        consignmentKeys.createConsignmentOrderAreaCode
      );
      if (phoneCountryId) {
        data.phoneCountryId = phoneCountryId;
      }

      let cacheData = getObjectSessionStorage(
        consignmentKeys.createConsignmentOrderData
      );
      if (cacheData) {
        Object.assign(data, cacheData);
        //不需要 tick, Address组件中有watch
        // nextTick(() => {
        //   refAddress?.value?.funcGetProvinces();
        //   // refAddress?.value?.funcGetCitys();
        //   // refAddress?.value?.funcGetCountys();
        // })
      }
    };

    getCacheData();
    onMounted(async () => {
      funcSourceSales();
      funcGetUserOfInventoryWarehouses();
      getCountriesList();
      //应在setup时直接赋值，否则会造成数据多次变化，引起address组件接口多次读取
      //getCacheData();
    });

    onBeforeRouteLeave((to, from, next) => {
      const orderRoutes = [
        "/consignment/order/create",
        "/consignment/order/addproduct",
        "/consignment/order/selectlogistics",
      ];
      if (!orderRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => orderRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(
            consignmentKeys.createConsignmentOrderData
          );
        }
      }
      next();
    });

    return {
      rules,
      refForm,
      refAddress,
      refInputNewSourceSale,
      ...toRefs(state),
      data,
      agentOrderLogisticsTypeEnum,
      handleNextStep,
      funcSourceSales,
      handleShowModalAddSourceSales,
      handleSaveModalAddSourceSales,
      funcFocusInput,
      handleChangeSelectConsignmentWarehouse,
      getLanguageName,
      handleCountryCodeChange,
      handleCheckZipCodeValid,
    };
  },
};
</script>

<style lang="less" scoped></style>
